.login-page-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../public/photos/COVER-MNBSALES.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}

.form-wrapper {
  width: 40vw;
}

@media screen and (max-width: 840px) {
  .form-wrapper {
    width: 60vw;
  }

  .login-page-wrapper {
    background-image: url("../../../public/photos/COVER-MNBSALES-MOBILE.jpeg");
    background-size: cover;
  }
}
