.ant-card-cover {
  padding: 4px !important;
  flex: 1 1 200px;
  /* max-height: 200px; */
  overflow: hidden;
}

.product-page__product-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.ant-ribbon-wrapper {
  display: flex;
  flex: 1 1 auto;
}

.hidden {
  display: none;
}

.visible {
  display: flex;
}
