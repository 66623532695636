@import "~antd/dist/antd.css";

* {
  box-sizing: border-box;
  /* box-shadow: 0 0 0 2px red inset; */
}

.site-layout-background {
  width: 100%;
}

.ant-layout-footer {
  transition: margin-left 0.3s;
}

.navbar-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
}

.navbar-picto-wrapper {
  display: flex;
  /* margin-right: 28px; */
  padding: 0 28px;
}

.navbar-picto {
  font-size: 24px;
  font-weight: 100;
  color: white;
  padding: 0 12px;
  margin-left: 8px;
  cursor: pointer;
}

.logo {
  text-align: center;
}

.logo-mnb-home {
  width: 200px;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-content-wrapper {
  margin: 0 16px;
}

.main-content {
  height: 100%;
  transition: margin-left 0.3s;
}

/** TAILLE DE LA LOUPE SVG */
.ant-table-filter-trigger {
  font-size: 22px;
}

.ant-card-actions {
  align-items: center;
}

@media screen and (max-width: 1081px) {
  .main-content {
    padding: 8px !important;
  }
}

@media screen and (max-width: 640px) {
  .main-content-wrapper {
    margin: 0;
  }

  .logo-mnb-home {
    width: 160px;
  }

  .main-content {
    padding: 8px !important;
  }

  .navbar-list {
    padding: 0;
    margin: 0;
  }

  .navbar-picto-wrapper {
    margin-right: 0;
    padding: 0 8px;
  }

  .navbar-picto {
    padding: 0 8px;
    font-size: 18px;
  }
}
