.cart-container{
    position: sticky;
    bottom: 0;
    box-shadow: 0.5px 0.5px 6px 0.5px #ccc;
    padding: 0;
    transition: margin-left 0.3s;
}

.cart-container > div{
    padding: 8px 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-container > div::after{
    content: none
}

.cart-container > div::before{
    content: none
}

@media screen and (max-width: 840px) {
    .cart-container > div{
        padding: 8px 12px;
    }
}