.searchBar__container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  position: sticky;
  top: 64px;
  margin-bottom: 8px;
  border-radius: 24px;
  z-index: 2;

  .searchBar-filters__container {
    flex: 1 1 auto;
    background-color: #ddd;
    padding: 8px 16px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;

    .searchBar-filters__header {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    .sarchBar-filters__category-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 4px;
      border-radius: 8px;

      .searchBar-filters__category-container {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      }
    }

    .visible {
      display: flex;
    }

    .hidden {
      display: none;
    }

    .displayUI {
      border-bottom: solid 1px #ccc;
      padding: 0 0 8px 0;
    }

    .hiddeUI {
      border-bottom: none;
      padding: 0;
    }

    .close-filters-cross {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  .searchBar-searchBar__container {
    flex: 1 1 80%;
    background-color: #ddd;
    padding: 8px 16px;
    border-radius: 16px;

    .ant-input-group-wrapper {
      border-radius: 12px;
      overflow: hidden;

      .ant-input-wrapper {
        .ant-input-affix-wrapper {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }

        .ant-input-group-addon {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;

          button {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }
}

.tag {
  border-radius: 4px;
  margin: 0;
}

/* .active-tag {
  border-radius: 4px;
} */

.inactive-tag {
  background-color: #eee;
  border-radius: 4px;
}

@media screen and (max-width: 970px) {
  .searchBar__container > span {
    width: 49%;
  }
}

@media screen and (max-width: 560px) {
  .searchBar__container > span {
    width: 100%;
  }
}
