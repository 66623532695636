.create-client__form-container{
  width: 80%;
  display: flex;
  border: solid 2px #ccc;
  border-radius: 4px;
  padding: 32px 56px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 840px){
  .create-client__form-container{
    width: 100%;
    padding: 16px 24px;
  }
}