.defaultPageWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.titleDefaultPage{
    font-size: 6rem;
}

@media screen and (max-width: 760px) {
    .titleDefaultPage{
        font-size: 3rem;
    }
}

@media screen and (max-width: 540px) {
    .titleDefaultPage{
        font-size: 2.5rem;
    }
}