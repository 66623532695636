.quantity-selector-container{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-number-quantity-selector{
    margin: 0 16px;
}

@media screen and (max-width: 640px){
    .input-number-quantity-selector{
        margin: 0;
    }
}