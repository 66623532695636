.go-to-cart-btn{
    background-color: #6ab04c;
    width: 128px;
    padding: 8px 12px;
    border: none;
    color: white;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
}

@media screen and (max-width: 840px){
    .go-to-cart-btn{
        padding: 8px 0;
        font-size: 1.2rem;
    }
}