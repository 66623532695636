.card-details-product {
  padding: 32px;
}

.ant-card-cover {
  display: flex;
  justify-content: center;
  align-items: center;
}

.details-product-image__container {
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.details-product-image {
  /* width: 60%; */
  margin-top: 4px;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.bloc-vertical-details-product {
  text-align: center;
  width: 100%;
  margin: 8px;
}

.bloc-details-product {
  background-color: #eee;
  border-radius: 4px;
  width: 100%;
  padding: 8px;
  text-align: center;
  font-size: 1.4rem;
}

.quantity-selector {
  width: 100%;
  height: 90%;
  margin: 8px;
}

@media screen and (max-width: 1081px) {
  .details-product-image__container {
    max-height: 400px;
  }

  /* .details-product-image {
    width: 100%;
  } */

  .card-details-product {
    padding: 8px;
  }
}
