.cart-page-container {
  height: 100%;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.qtyBtn {
  border: none;
  background-color: white;
  transition: 0.4s all;
  cursor: pointer;
  display: flex;
  padding: 0;
}

.ant-table-row:hover .qtyBtn {
  background-color: #fafafa;
}

.icon-plus svg,
.icon-moins svg {
  width: 1.4rem;
  height: 1.4rem;
  transition: 0.4s all;
}

.icon-plus:hover svg,
.icon-moins:hover svg {
  fill: #6ab04c;
}
